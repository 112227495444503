import { ButtonIconWrapper, ButtonWrapper } from './Button.styles';
import { ButtonProps } from './Button.types';

const ButtonStyleHandler = ({
  children, variation, dark, disabled, ...rest
}: ButtonProps) => {
  let colorControl = '#3484c3';

  if (dark) colorControl = '#fff';

  if (disabled) colorControl = '#ccc';

  switch (variation) {
    case 'outlined':
      return (
        <ButtonWrapper
          {...rest}
          color={colorControl}
          backgroundColor="transparent"
          border="solid"
          borderWidth={2}
          borderColor={colorControl}
          fontWeight={500}
          pt={0}
          pb={0}
          disabled={disabled}
        >
          {children}
        </ButtonWrapper>
      );

    default:
      return (
        <ButtonWrapper
          {...rest}
          color={dark ? '#3484c3' : 'white'}
          backgroundColor={colorControl}
          borderWidth={0}
          disabled={disabled}
        >
          {children}
        </ButtonWrapper>
      );
  }
};

export const Button = ({
  children, variation, fullWidth, icon, ...rest
}: ButtonProps) => (
  <ButtonStyleHandler
    {...rest}
    variation={variation}
    display="flex"
    alignItems="center"
    justifyContent="center"
    px="1.625rem"
    borderRadius="1rem"
    fontSize="0.875rem"
    // width={fullWidth ? '100%' : 'auto'}
    height="2.5rem"
    fontFamily="Poppins"
  >
    {icon && (
      <ButtonIconWrapper mr="0.375rem" mt="0.325rem">{icon}</ButtonIconWrapper>
    )}
    {children}
  </ButtonStyleHandler>
);
