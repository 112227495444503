import * as S from '../../../../theme';
import { Logo } from '../../../atoms/Logo';

export const FormHeader = () => (
  <S.Row id="Header" justifyContent="center">
    <S.Col justifyContent="center">
      <Logo white pt="4.62rem" pb="3.5rem" />
    </S.Col>
  </S.Row>
);
