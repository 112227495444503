/* eslint-disable react/button-has-type */
import { useEffect, useState } from 'react';
import { FiLogOut } from 'react-icons/fi';

import { ReactComponent as IconLab } from '../../assets/icons/icon_lab.svg';

import { useAuth } from '../../hooks/useAuth';
import { getAllAppointments } from '../../services/appointments';

import { Avatar } from '../../components/atoms/Avatar/Avatar';
import { Button } from '../../components/atoms/Button';
import { Header } from '../../components/molecules/Header';
import { MegaFooter } from '../../components/organisms/MegaFooter';

import { AppointmentsList } from './AppointmentsList';
import { AppointmentsListType } from '../../types';

import {
  AppointmentContainer,
  AppointmentContentContainer,
  Grid, GridItem, SubHeader, SubHeaderTitle, SubTitleWrapper,
} from '../../theme';

export const Appointments = () => {
  const [appointments, setAppointments] = useState<AppointmentsListType>();
  const [isLoading, setIsLoading] = useState(true);
  const { user, Logout } = useAuth();

  useEffect(() => {
    (async () => {
      try {
        const { data } = await getAllAppointments();

        setAppointments(data);
      } catch (err) {
        throw new Error(`Can't fetch appointments: ${err}`);
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  return (
    <Grid>
      <GridItem gridArea="header">
        <Header userName={user?.patientName || ''}>
          <Button variation="outlined" icon={<FiLogOut />} onClick={Logout}>
            Sair
          </Button>
        </Header>
      </GridItem>

      <GridItem gridArea="sub-header">
        <SubHeader>
          <AppointmentContainer>
            <SubTitleWrapper>
              <Avatar>
                <IconLab />
              </Avatar>
              <SubHeaderTitle>Atendimentos</SubHeaderTitle>
            </SubTitleWrapper>
          </AppointmentContainer>
        </SubHeader>
      </GridItem>

      <GridItem gridArea="content">
        <AppointmentContentContainer>
          {
            !appointments && isLoading
              ? 'Carregando...'
              : <AppointmentsList appointments={appointments} />
          }
        </AppointmentContentContainer>
      </GridItem>

      <GridItem gridArea="footer">
        <MegaFooter />
      </GridItem>
    </Grid>
  );
};
