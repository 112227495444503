import { Background } from '@alliar/jarvis';
import { useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';
import { Recaptcha } from '../../components/molecules/Recaptcha';
import { Footer } from '../../components/molecules/Footer';
import { NewPasswordForm } from '../../components/molecules/Forms/NewPassword';
import {
  Card, Col, FormContainer, Row,
} from '../../theme';

import { InvalidToken } from './Steps/InvalidToken';
import { PasswordFormWrapper } from './Steps/PasswordFormWrapper';
import { SuccessPasswordChange } from './Steps/SuccessPasswordChange';

import ImageBg from '../../assets/imgs/bg_image.png';

import { useForm } from '../../hooks/useForm';
import { NewPasswordData } from '../../components/molecules/Forms/NewPassword/types';
import { changePassword, verifyToken } from '../../services/user';
import { FormHeader } from '../../components/molecules/Forms/FormHeader';

export const NewPassword = () => {
  const [isTokenValid, setIsTokenValid] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const {
    formData,
    handleChange,
    handleSubmit,
    isSubmitting,
  } = useForm(submitNewPassword, handleValidation);
  const { recoveryToken } = useParams();

  useEffect(() => {
    (async function () {
      const { error } = await verifyToken(recoveryToken || '');
      if (error) {
        return setIsTokenValid(false);
      }

      return setIsTokenValid(true);
    }());
  }, []);

  function handleValidation(data: NewPasswordData) {
    const passwordConfirmation = [];

    if (!data.passwordConfirmation) {
      passwordConfirmation.push('O campo de confirmação de senha é obrigatório');
    }

    if (data.passwordConfirmation && data.password !== data.passwordConfirmation) {
      passwordConfirmation.push('As senhas devem ser iguais');
    }

    return { passwordConfirmation };
  }

  async function submitNewPassword(data: NewPasswordData) {
    if (recoveryToken) {
      const { error } = await changePassword({ token: recoveryToken, password: data.password });

      if (!error) {
        setFormSubmitted(true);
      }
    } else {
      setIsTokenValid(false);
    }
  }

  const renderFormState = (wasSubmitted: boolean) => (
    !wasSubmitted
      ? (
        <PasswordFormWrapper>
          <NewPasswordForm
            formData={formData}
            isSubmitting={isSubmitting}
            handleSubmit={handleSubmit}
            handleChange={handleChange}
          />
        </PasswordFormWrapper>
      )
      : <SuccessPasswordChange />
  );

  return (
    <>
      <FormContainer>
        <FormHeader />

        <Row id="Content" justifyContent="center" alignSelf="center" alignItems="center">
          <Col>
            <Card bg="#fff" p="3.5rem" maxWidth={482}>
              {
                !isTokenValid
                  ? <InvalidToken />
                  : renderFormState(formSubmitted)
              }
            </Card>
          </Col>
        </Row>
        <Row id="Captcha" flex={1} justifyContent="center">
          <Col justifyContent="flex-end">
            <Recaptcha />
          </Col>
        </Row>
        <div>
          <Row id="Footer">
            <Footer bgColor="white" />
          </Row>
        </div>
      </FormContainer>
      <Background sliced imgSrc={ImageBg} />
    </>
  );
};
