import { ServerResponse } from '.';
import { httpClient } from './config';

export const getMedicalExamResults = async ({
  examId,
}: {
  patientName: string;
  examId: string;
}) => httpClient.get<any, ServerResponse>('/api/medical_exams_result', {
  params: {
    exam_id: examId,
  },
});

export const getMedicalExams = async ({
  appointmentId,
}: {
  appointmentId: string ;
}) => httpClient.get<any, ServerResponse>('/api/medical_exams', {
  params: {
    appointmentId,
  },
});
