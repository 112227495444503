/* eslint-disable @typescript-eslint/no-unused-vars */
import { ServerResponse } from '.';
import { User, UserLoginData } from '../types';
import { httpClient } from './config';

export const checkDocument = async ({
  cpf,
}: User) => httpClient.post<User, ServerResponse>('/api/verifyPatient ', {
  cpf,
});

export const userLogin = async ({
  cpf,
  password,
  captchaResponse,
}: UserLoginData) => httpClient.post<UserLoginData, ServerResponse>('/api/login ', {
  cpf,
  password,
  'g-recaptcha-response': captchaResponse,
});

export const userDependentLogin = async ({
  cpf,
  password,
  captchaResponse,
  prontuarioId,
  patientName,
  patientBirth,
}: UserLoginData & {
  prontuarioId:number,
  patientName:string,
  patientBirth:string
}) => httpClient.post<UserLoginData, ServerResponse>('/api/token ', {
  cpf,
  password,
  prontuarioId,
  patientName,
  patientBirth,
  'g-recaptcha-response': captchaResponse,
});

export const changePassword = async (
  { token, password }: { token: string, password: string },
) => httpClient.post<any, any>('/api/changePassword', {
  password,
}, {
  headers: {
    Authorization: `Bearer ${token}`,
  },
});

export const verifyToken = async (
  token: string,
) => httpClient.get<any, any>('/api/tokenValidate', {
  headers: {
    Authorization: `Bearer ${token}`,
  },
});
