import { FormCardHeader } from '../../../theme';
import { ConfirmationStepHeader } from './ConfirmationStepHeader';
import { StepHeader } from './StepHeader';
import { CardHeaderProps } from './types';

export const CardHeader = ({ step }: CardHeaderProps) => {
  const hasCompletedForm = step === 1;

  return (
    <FormCardHeader>
      {
        hasCompletedForm
          ? <ConfirmationStepHeader />
          : <StepHeader />
      }
    </FormCardHeader>
  );
};
