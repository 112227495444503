import { Input } from '@alliar/jarvis';
import { ChangeEvent } from 'react';
import { applyCpfMaskOnTargetValue } from '../../../../utils/cpfMask';
import { FormProps } from '../types';
import * as S from '../../../../theme';

export const DocumentCheckForm = ({
  formData,
  formErros,
  onChange,
}: FormProps) => (
  <S.Box mt={4}>
    <Input
      id="DocumentCheckFormInput"
      label="Digite seu CPF"
      type="text"
      name="cpf"
      value={formData.cpf || ''}
      onChange={
        (e: ChangeEvent<HTMLInputElement>) => onChange(applyCpfMaskOnTargetValue(e))
      }
      hintText={formErros.cpf ? formErros.cpf[0] : undefined}
      color={formErros.cpf && formErros.cpf[0] ? 'red' : ''}
      maxLength={14}
      autoFocus
    />
  </S.Box>
);
