import { ItemContainer } from './ExamCardBodyInfoItem.styles';
import { ExamCardBodyInfoItemProps } from './ExamCardBodyInfoItem.types';

export const ExamCardBodyInfoItem = (
  { title, value }: ExamCardBodyInfoItemProps,
) => (
  <ItemContainer
    display="flex"
    flexDirection={{ sm: 'column', lg: 'row' }}
    gridGap={{ sm: '0.5rem', lg: '0.313rem' }}
    alignItems={{ sm: 'flex-start', lg: 'center' }}
    flexWrap="wrap"
    fontSize={{ sm: '0.75rem', lg: '0.875rem' }}
  >
    <strong>{title}</strong>
    {' '}
    {value}
  </ItemContainer>
);
