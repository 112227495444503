import { FiMail } from 'react-icons/fi';
import { Avatar } from '../../atoms/Avatar';
import * as S from '../../../theme';

export const ConfirmationStepHeader = () => (
  <>
    <Avatar bgColor="#3484C3">
      <FiMail color="white" />
    </Avatar>

    <S.H1 textAlign={{ sm: 'center' }} mt="1.25rem">
      Confira seu e-mail!
    </S.H1>

    <S.H3 mt={1}>
      Por segurança, recomendamos que você crie uma nova senha.
      Enviaremos um e-mail com instruções de como redifinir
      sua senha para você acessar o portal de resultados
    </S.H3>
  </>
);
