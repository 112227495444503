import { useState } from 'react';
import { Button, Input } from '@alliar/jarvis';

import { usePasswordStr } from '../../../../hooks/usePasswordStr';
import { ValidationsList } from './ValidationsList';
import { NewPasswordProps } from './types';
import * as S from '../../../../theme';

export const NewPasswordForm = ({
  formData,
  isSubmitting,
  handleSubmit,
  handleChange,
}: NewPasswordProps) => {
  const { testedValidators, isAllValid } = usePasswordStr(formData.password || '');

  const [passwordConfirmisVisible, setPasswordConfirmisVisible] = useState(false);
  const [passwordisVisible, setPasswordisVisible] = useState(false);

  const confirmationDoesMatch = formData.password === formData?.passwordConfirmation;
  const canSubmitForm = !isSubmitting && isAllValid && confirmationDoesMatch;

  const getInputColor = (data: string, validation: boolean) => (data ? (validation ? '#49A41D' : '#EC6360') : '');

  return (
    <S.Form
      onSubmit={(e) => {
        e.preventDefault();

        if (canSubmitForm) {
          handleSubmit(e);
        }
      }}
      id="NewPasswordForm"
      mt="1.5rem"
    >
      <div style={{ width: '100%' }}>
        <Input
          rightIcon={passwordisVisible ? 'eye-off' : 'eye'}
          rightIconClick={() => setPasswordisVisible(!passwordisVisible)}
          type={passwordisVisible ? 'text' : 'password'}
          label="Digite sua nova senha"
          id="new_password_field"
          name="password"
          value={formData.password || ''}
          color={getInputColor(formData.password, isAllValid)}
          onChange={handleChange}
          autoFocus
        />

        <ValidationsList
          testedValidators={testedValidators}
          dataToVerify={formData.password}
        />
      </div>

      <Input
        rightIcon={passwordConfirmisVisible ? 'eye-off' : 'eye'}
        rightIconClick={() => setPasswordConfirmisVisible(!passwordConfirmisVisible)}
        type={passwordConfirmisVisible ? 'text' : 'password'}
        label="Confirme sua senha"
        id="password_confirmation_field"
        name="passwordConfirmation"
        value={formData.passwordConfirmation || ''}
        onChange={handleChange}
        hintText={formData.passwordConfirmation && !confirmationDoesMatch ? 'As senhas devem ser iguais' : ''}
        color={getInputColor(formData.passwordConfirmation, confirmationDoesMatch)}
      />

      <Button
        type="submit"
        disabled={!canSubmitForm}
        variant="main"
        label={isSubmitting ? 'Carregando...' : 'Enviar'}
        width="100%"
      />
    </S.Form>
  );
};
