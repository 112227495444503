import React from 'react';
import { FiUser } from 'react-icons/fi';
import { Avatar } from '../../../../components/atoms/Avatar';
import {
  FormCardHeader, ThemeLink, H1, H3,
} from '../../../../theme';

export const PasswordFormWrapper: React.FC = ({ children }) => (
  <>
    <FormCardHeader>
      <Avatar bgColor="white">
        <FiUser color="#3a88c5" />
      </Avatar>
      <H1>Crie uma nova senha</H1>
      <H3>
        Preencha os campos abaixo para redefinir sua nova senha
      </H3>
    </FormCardHeader>

    {children}

    <ThemeLink to="/login" mx="auto" mt="24px">
      Voltar para o login
    </ThemeLink>
  </>
);
