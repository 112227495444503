/* eslint-disable no-param-reassign */
/* eslint-disable consistent-return */
import { AxiosRequestConfig, AxiosError, AxiosResponse } from 'axios';
import { getStorageItem } from '../../utils/localStorage';

const requestInterceptor = async (config: AxiosRequestConfig) => {
  const token = getStorageItem('Token');

  config.headers = {
    ...config.headers,
    ...(!config.headers?.Authorization && !!token ? {
      Authorization: `Bearer ${token}`,
    } : {}),
  };

  return config;
};

const responseSuccessInterceptor = ({ data }: AxiosResponse) => {
  if (typeof data === 'string') return data;

  return ('data' in data ? data : { data });
};

const responseErrorInterceptor = (error: AxiosError) => {
  const hasUserLogged = localStorage.getItem('@ROL:User');

  if (hasUserLogged && error.response?.status === 401) {
    localStorage.removeItem('@ROL:User');
    localStorage.removeItem('@ROL:Token');

    document.location.reload();
  }

  return ({ error: error.toJSON() });
};

export { requestInterceptor, responseSuccessInterceptor, responseErrorInterceptor };
