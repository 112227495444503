import styled from 'styled-components';

export const ListWrapper = styled.div`
  font-size: 12px;
  min-height: 8em;
  column-fill: auto;
  column-gap: 8px;
  margin-inline: 8px;

  @media (min-width: 500px) {
    columns: 2 1em;
  }
`;
