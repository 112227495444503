import { useState } from 'react';
import { FiMail, FiUser } from 'react-icons/fi';
import { Background } from '@alliar/jarvis';

import { Avatar } from '../../components/atoms/Avatar';
import { PasswordRecoveryForm } from '../../components/molecules/Forms';
import { Footer } from '../../components/molecules/Footer';

import { useForm } from '../../hooks/useForm';
import { validateLogin } from '../../utils/validateLogin';

import ImageBg from '../../assets/imgs/bg_image.png';

import { UserLoginData } from '../../types';
import { removeCpfMask } from '../../utils/cpfMask';
import {
  H1,
  H3,
  Col,
  Row,
  Card,
  ThemeLink,
  FormContainer,
  FormCardHeader,
} from '../../theme';
import { Recaptcha } from '../../components/molecules/Recaptcha';
import { recoverPasswordMailRequest, recoverPasswordSearchForUsers } from '../../services/recoverPasswordSearchForUsers.ts';
import { FormHeader } from '../../components/molecules/Forms/FormHeader';

export const PasswordRecovery = () => {
  const [accountsInfo, setAccountsInfo] = useState<string[]>([]);
  const [sended, setSended] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const {
    formData,
    errors,
    handleChange,
    handleSubmit,
  } = useForm<UserLoginData>(onSubmitCallback, validateLogin);

  async function onSubmitCallback({ cpf }: any) {
    setIsLoading(true);
    const cpfWithoutMask = removeCpfMask(cpf);

    recoverPasswordSearchForUsers({ cpf: cpfWithoutMask })
      .then(({ data }: any) => {
        if (!data.length) {
          setSended(true);
          throw new Error('CPF com problemas, entre em contato com o suporte');
        }

        if (data.length === 1) {
          const { prontuarioId } = data[0];
          recoverPasswordMailHandler(prontuarioId);
          return;
        }

        setAccountsInfo(data);
        setIsLoading(false);
      })
      .catch((err: any) => {
        console.log(err);
        setIsLoading(false);
      });
  }

  async function recoverPasswordMailHandler(prontuarioId: any) {
    recoverPasswordMailRequest({ prontuarioId })
      .then(() => setSended(true))
      .catch((err: any) => {
        console.log(err);
        setIsLoading(false);
      });
  }

  return (
    <>
      <FormContainer>
        <div>
          <FormHeader />

          <Row id="Content" justifyContent="center" alignSelf="center" alignItems="center">
            <Col>
              <Card bg="#fff" p={{ lg: '3.175rem', sm: '1.5rem' }} py={{ sm: '2rem' }} maxWidth={{ lg: 482, sm: 280 }} m={{ sm: '1.25rem' }}>
                <FormCardHeader>
                  <Avatar bgColor={sended ? '#3484C3' : 'white'}>
                    {sended ? <FiMail color="white" /> : <FiUser color="#3a88c5" />}
                  </Avatar>
                  <H1 textAlign={{ sm: 'center' }}>{sended ? 'Confira seu e-mail!' : 'Esqueceu sua senha?'}</H1>
                  <H3>
                    {!accountsInfo.length && !sended
                      ? 'Para redifinir sua senha, digite seu CPF abaixo' : null}
                    {accountsInfo.length && !sended
                      ? 'Você tem mais de um dependente cadastrado em seu CPF. Qual conta você deseja alterar a senha?' : null}
                    {sended ? 'Se seu CPF estiver cadastrado no Portal, enviaremos um e-mail com instruções de como redifinir sua senha.' : null}
                  </H3>
                </FormCardHeader>

                {!accountsInfo.length && !sended ? (
                  <PasswordRecoveryForm
                    formData={formData}
                    formErros={errors}
                    onChange={handleChange}
                    handleSubmit={handleSubmit}
                    isSubmitting={isLoading}
                  />
                ) : null}

                <ThemeLink to="/login" mx="auto" mt="1.5rem">
                  Voltar para o login
                </ThemeLink>
              </Card>
            </Col>
          </Row>
          <Row id="Captcha" justifyContent="center">
            <Recaptcha />
          </Row>
        </div>
        <div>
          <Row id="Footer">
            <Footer bgColor="white" />
          </Row>
        </div>
      </FormContainer>
      <Background sliced imgSrc={ImageBg} />
    </>
  );
};
