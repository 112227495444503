/*
  Os códigos comentados serão para uso futuro.
*/

import { A } from '@alliar/jarvis';

// import LogoMultiscanWhite from '../../assets/imgs/logo-multiscan-white.png';

// import { Logo } from '../../atoms/Logo';

import { Footer } from '../../molecules/Footer';
// import { ContactUs } from '../../molecules/ContactUs';
// import { SocialLinks } from '../../molecules/SocialLinks';
// import { QuickLinks } from '../../molecules/QuickLinks';

// import { MegaFooterWrapper, Content } from './MegaFooter.styles';

export const MegaFooter = () => (
  <Footer bgColor="#091A33">
    <>
      2022 Alliar. Todos os direitos reservados.
      {' '}
      <A href="https://www.multiscan.med.br/politica-privacidade">Política de privacidade</A>
    </>
  </Footer>
);

// export const MegaFooter = () => (
//   <MegaFooterWrapper>
//     <Content>
//       <Logo src={LogoMultiscanWhite} alt="Logo da Multiscan" />
//       <QuickLinks id="MegaFooter_QuickLinks" />
//       <ContactUs id="MegaFooter_ContactUs" />
//       <SocialLinks id="MegaFooter_SocialLinks" />
//     </Content>

//     <Footer color="white" bgColor="#091A33">
//       <>
//         2022 Alliar. Todos os direitos reservados.
//         {' '}
//         <A href="https://www.multiscan.med.br/politica-privacidade">Política de privacidade</A>
//       </>
//     </Footer>
//   </MegaFooterWrapper>
// );
