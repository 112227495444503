import { FooterContainer } from './Footer.styles';
import { FooterProps } from './Footer.types';

export const Footer = ({ bgColor, color, children }: FooterProps) => (
  <FooterContainer color={color} bgColor={bgColor}>
    <span>{children || '2022 Alliar. Todos os direitos reservados'}</span>
  </FooterContainer>
);

Footer.defaultProps = {
  bgColor: 'transparent',
  color: 'black',
  children: '',
};
