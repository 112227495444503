import { FiUser } from 'react-icons/fi';
import { Avatar } from '../../atoms/Avatar';
import * as S from '../../../theme';

export const StepHeader = () => (
  <>
    <Avatar bgColor="white">
      <FiUser color="#3a88c5" />
    </Avatar>

    <S.H1
      textAlign={{ sm: 'center' }}
      mt="1.25rem"
      mb="0"
    >
      Infome seus dados para acessar os resultados
    </S.H1>

    <S.H3 mt=".5rem">
      Preencha o campo abaixo
    </S.H3>
  </>
);
