import { SyntheticEvent, useState } from 'react';
import { Background } from '@alliar/jarvis';
import { UserLoginData } from '../../types';

import ImageBg from '../../assets/imgs/bg_image.png';

import { Footer } from '../../components/molecules/Footer';
import { Recaptcha } from '../../components/molecules/Recaptcha';

import { useAuth } from '../../hooks/useAuth';
import { useForm } from '../../hooks/useForm';

import { validateLogin } from '../../utils/validateLogin';

import { userLogin, checkDocument, userDependentLogin } from '../../services/user';
import { CardHeader } from '../../components/molecules/CardHeader';
import { DocumentCheckForm, PasswordForm } from '../../components/molecules/Forms';
import { removeCpfMask } from '../../utils/cpfMask';
import { RadioButton } from '../../components/molecules/Forms/RadioOptions/RadioButton';
import { RadioProps } from '../../components/molecules/Forms/RadioOptions/types';
import * as S from '../../theme';
import { linkSwitch } from '../../utils/linkSwitch';
import { FormHeader } from '../../components/molecules/Forms/FormHeader';

export const Login = () => {
  const [stepControler, setStepControler] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [patientsOptions, setPatientsOptions] = useState<RadioProps[]>([]);
  const [patientSelect, setPatientSelect] = useState('');
  const [radioUserData, setRadioUserData] = useState<any>();

  const {
    AuthPass,
    AuthError,
    loginError,
    loginErrorClear,
  } = useAuth();

  const {
    formData, errors, handleChange, handleSubmit,
  } = useForm<UserLoginData>(onSubmitCallback, validateLogin);

  async function onSubmitCallback(userData: UserLoginData) {
    setIsLoading(true);
    loginErrorClear();

    // @ts-ignore
    const captchaResponse = await window.grecaptcha.execute(
      '6Lf7YRIeAAAAAHo50SrUDLnoqIIiHYe0i_6jX7eK',
      { action: 'submit' },
    );

    const { cpf, password } = userData;

    if (cpf && !password) {
      const cpfFormated = removeCpfMask(cpf);

      try {
        const { data } = await checkDocument({ cpf: cpfFormated });

        if (!data) errors.cpf = ['Ops! O CPF informado é inválido. Tente novamente.'];

        setStepControler(data);
      } catch (err) {
        errors.cpf = ['Ops! O CPF informado é inválido. Tente novamente.'];
        console.error('❌ Erro de servidor: ', err);
      } finally {
        setIsLoading(false);
      }

      return;
    }

    const { data, error } = await userLogin({
      cpf: removeCpfMask(userData.cpf),
      password: userData.password,
      captchaResponse,
    });

    if (error) {
      AuthError(error);
      setIsLoading(false);

      return;
    }

    const { patients } = data;

    if (patients.length > 1) {
      setRadioUserData({
        ...userData, users: [...patients], captchaResponse,
      });
      setPatientsOptions(patients);
      setStepControler(3);
      setIsLoading(false);
    } else {
      const { token } = data;
      const { patientName } = data.patients[0];

      AuthPass({
        token,
        patientName,
        cpf: userData.cpf,
      });
    }
  }

  const radioSubmit = async (event: SyntheticEvent<Element, Event>) => {
    event.preventDefault();
    setIsLoading(true);
    const { users } = radioUserData;

    const { patientBirth, patientName, prontuarioId } = users
      .filter((item: any) => item.prontuarioId === Number(patientSelect))[0];

    const {
      captchaResponse, cpf, password,
    } = radioUserData;

    try {
      const { data } = await userDependentLogin({
        cpf,
        password,
        captchaResponse,
        prontuarioId,
        patientName,
        patientBirth,
      });

      const { token } = data;

      AuthPass({
        token,
        patientName,
        cpf,
      });
    } catch (err) {
      console.log('🚀 ~ err', err);
      setIsLoading(false);
    }
  };

  return (
    <>
      <S.FormContainer>
        <S.FormContainerWrapper justifyContent="center">
          <FormHeader />

          <S.Row
            id="Content"
            justifyContent="center"
            alignSelf="center"
            alignItems="center"
          >
            <S.Col>
              <S.Card
                bg="#fff"
                p={{ lg: '3.5rem', sm: '1.575rem' }}
                maxWidth="30rem"
                minWidth="18rem"
              >
                <CardHeader step={stepControler} />

                {stepControler === 0 ? (
                  <>
                    <DocumentCheckForm
                      formData={formData}
                      formErros={errors}
                      onChange={handleChange}
                    />
                    <S.Button
                      id="DocumentCheckFormBtn"
                      variant="main"
                      onClick={handleSubmit}
                      disabled={isLoading || !formData.cpf}
                      label={!isLoading ? 'Continuar' : 'Carregando...'}
                      width="100%"
                      mt={4}
                    />
                  </>
                ) : null}

                {stepControler === 2 ? (
                  <>
                    <PasswordForm
                      formData={formData}
                      formErros={loginError ? { password: [loginError] } : {}}
                      onChange={handleChange}
                    />
                    <S.Button
                      id="NewPasswordFormBtn"
                      variant="main"
                      onClick={handleSubmit}
                      disabled={isLoading || !formData.password}
                      label={!isLoading ? 'Entrar' : 'Carregando...'}
                      width="100%"
                      mt={4}
                    />
                  </>
                ) : null}

                {stepControler === 3 ? (
                  <>
                    <RadioButton
                      items={patientsOptions}
                      chekedValue={patientSelect}
                      setChekedValue={setPatientSelect}
                    />
                    <S.Button
                      id="NewPasswordFormBtn"
                      variant="main"
                      onClick={radioSubmit}
                      disabled={isLoading || !patientSelect}
                      label={!isLoading ? 'Entrar' : 'Carregando...'}
                      width="100%"
                    />
                  </>
                ) : null}

                {linkSwitch(stepControler)}
              </S.Card>
            </S.Col>
          </S.Row>
          <S.Row id="Captcha" justifyContent="center">
            <Recaptcha />
          </S.Row>
        </S.FormContainerWrapper>
        <div>
          <S.Row id="Footer" justifyContent="center">
            <Footer bgColor="white" />
          </S.Row>
        </div>
      </S.FormContainer>
      <Background sliced imgSrc={ImageBg} />
    </>
  );
};
