import { Button } from '../../../atoms/Button';
import {
  CardBody, CardContainer, CardHeadLine, CardHeadLineEm,
} from '../Card.styles';
import { AppointmentCardProps } from './AppointmentCard.types';

import { BodyInfoItem } from './BodyInfoItem';

export const AppointmentCard = ({
  appointmentNumber,
  deliveryDate,
  appointmentDate,
  onClick,
}: AppointmentCardProps) => (
  <CardContainer>
    <CardHeadLine
      fontFamily="'Poppins', sans-serif"
      mx={0}
      mt={0}
      mb={{ sm: '0.875rem', lg: '0.75rem' }}
      gridGap="0.25rem"
      display="flex"
      alignItems="baseline"
      flexWrap="wrap"
      fontSize={{ sm: '0.75rem', lg: '1rem' }}
    >
      Número do atendimento
      {' '}
      <CardHeadLineEm
        color="#0C274D"
        fontWeight="600"
      >
        {appointmentNumber}
      </CardHeadLineEm>
    </CardHeadLine>

    <CardBody
      width="100%"
      py={{ sm: '0.75rem', lg: '0.5rem' }}
      px="0.75rem"
      display="flex"
      justifyContent="space-between"
      flexDirection={{ sm: 'column', lg: 'row' }}
      bg="#F5F5F5"
      backgroundPosition="0% 0%"
      backgroundRepeat="no-repeat"
      borderRadius="0.25rem"
      gridGap="0.875rem"
    >
      <BodyInfoItem title="Data do exame" value={appointmentDate} />
      <BodyInfoItem title="Previsão da entrega" value={deliveryDate} />

      <Button alignSelf="flex-end" onClick={onClick}>Ver atendimento</Button>
    </CardBody>
  </CardContainer>
);
