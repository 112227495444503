type Item = 'Token' | 'User' | 'Brand';

const storageIdentifier = '@ROL:';

export const getStorageItem = <T = string>(item: Item): T | null => {
  const storageValue = localStorage.getItem(`${storageIdentifier + item}`);

  if (!storageValue || storageValue === 'undefined') return null;

  return JSON.parse(storageValue);
};
export const setStorageItem = (item: Item, content: unknown): void => localStorage.setItem(`${storageIdentifier + item}`, JSON.stringify(content));

export const removeStorageItem = (item: Item) => {
  localStorage.removeItem(item);
};
