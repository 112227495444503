import React, {
  createContext, useEffect, useMemo, useState,
} from 'react';

import { User } from '../types';
import { AuthContextData } from './authContext.types';

import { getStorageItem, setStorageItem } from '../utils/localStorage';

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

export const AuthProvider: React.FC = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  const [loginError, setLoginError] = useState<string | null>(null);

  useEffect(() => {
    const storageUser = getStorageItem<User>('User');
    const storageToken = getStorageItem('Token');

    if (storageUser && storageToken) setUser(storageUser);
  }, []);

  async function AuthPass({
    token,
    patientName,
    cpf,
  }: User) {
    setStorageItem('User', { patientName, cpf });
    setStorageItem('Token', token);

    setUser({ patientName, cpf });
  }

  async function AuthError(error: any) {
    if (error?.status === 500) {
      setLoginError('Erro ao realizar login, tente novamente mais tarde.');
      return;
    }

    if (error?.status === 401) {
      setLoginError('Ops! A senha é inválida. Tente novamente.');
      return;
    }

    if (error?.status === 404) {
      setLoginError('Estamos passando por uma manutenção no momento, tente novamente mais tarde.');
    }
  }

  const loginErrorClear = () => setLoginError(null);

  function Logout() {
    localStorage.clear();

    setUser(null);
  }

  const userObj = useMemo(() => ({
    isLoggedIn: Boolean(user),
    loginError,
    loginErrorClear,
    user,
    AuthPass,
    AuthError,
    Logout,
  }), [user, loginError]);

  return (
    <AuthContext.Provider value={userObj}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
