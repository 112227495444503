import { CaptchaMessage } from '@alliar/jarvis';
import RecaptchaLogoSvg from '../../../assets/icons/logo_recaptcha.svg';
import { ImgRecaptcha, RecaptchaCol } from '../../../theme';

export const Recaptcha = () => (
  <RecaptchaCol justifyContent="center" textAlign="center" alignSelf="center" alignItems="center">
    <ImgRecaptcha src={RecaptchaLogoSvg} alt="Logo Recaptcha" />
    <CaptchaMessage style={{ margin: 0 }} />
  </RecaptchaCol>
);
