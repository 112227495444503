import { useState } from 'react';
import { FiMail, FiUser } from 'react-icons/fi';
import { Background } from '@alliar/jarvis';

import { Avatar } from '../../components/atoms/Avatar';
import { Footer } from '../../components/molecules/Footer';

import ImageBg from '../../assets/imgs/bg_image.png';

import {
  H1,
  H3,
  Col,
  Row,
  Card,
  ThemeLink,
  FormContainer,
  FormCardHeader,
} from '../../theme';
import { Recaptcha } from '../../components/molecules/Recaptcha';
import { Logo } from '../../components/atoms/Logo';

export const PasswordChange = () => {
  const [accountsInfo] = useState<string[]>([]);
  const [sended] = useState(false);

  return (
    <>
      <FormContainer>
        <div>
          <Row
            id="Header"
            justifyContent="center"
            alignSelf="center"
            alignItems="center"
          >
            <Col justifyContent="center">
              <Logo pt={10} />
            </Col>
          </Row>
          <Row
            id="Content"
            justifyContent="center"
            alignSelf="center"
            alignItems="center"
          >
            <Col>
              <Card
                bg="#fff"
                p={{ lg: '3.175rem', sm: '1.5rem' }}
                py={{ sm: '2rem' }}
                maxWidth={{ lg: 482, sm: 280 }}
                m={{ sm: '1.25rem' }}
              >
                <FormCardHeader>
                  <Avatar bgColor={sended ? '#3484C3' : 'white'}>
                    {sended ? (
                      <FiMail color="white" />
                    ) : (
                      <FiUser color="#3a88c5" />
                    )}
                  </Avatar>
                  <H1 textAlign={{ sm: 'center' }}>
                    {sended ? 'Confira seu e-mail!' : 'Esqueceu sua senha?'}
                  </H1>
                  <H3>
                    {!accountsInfo.length && !sended
                      ? 'Para redifinir sua senha, digite seu CPF abaixo'
                      : null}
                    {accountsInfo.length && !sended
                      ? 'Você tem mais de um dependente cadastrado em seu CPF. Qual conta você deseja alterar a senha?'
                      : null}
                    {sended
                      ? 'Se seu CPF estiver cadastrado no Portal, enviaremos um e-mail com instruções de como redifinir sua senha.'
                      : null}
                  </H3>
                </FormCardHeader>

                <ThemeLink to="/login" mx="auto" mt="1.5rem">
                  Voltar para o login
                </ThemeLink>
              </Card>
            </Col>
          </Row>
          <Row id="Captcha" justifyContent="center">
            <Recaptcha />
          </Row>
        </div>
        <div>
          <Row id="Footer">
            <Footer bgColor="white" />
          </Row>
        </div>
      </FormContainer>
      <Background sliced imgSrc={ImageBg} />
    </>
  );
};
