import { useEffect, useState } from 'react';

function hasMinEightCharacters(value: string) {
  return value.length >= 8;
}

function hasNumbers(value: string) {
  return (/\d/g).test(value);
}

function hasLowerCase(value: string) {
  return (/\.*[a-z]/g).test(value);
}

function hasUpperCase(value: string) {
  return (/\.*[A-Z]/g).test(value);
}

function hasSpecialCharacters(value: string) {
  return (/\.*\W.*/g).test(value);
}

const passwordValidators = [
  {
    text: 'Mínimo 8 caracteres',
    rule: hasMinEightCharacters,
  },
  {
    text: 'Letra minúscula',
    rule: hasLowerCase,
  },
  {
    text: 'Letra maiúscula',
    rule: hasUpperCase,
  },
  {
    text: 'Números',
    rule: hasNumbers,
  },
  {
    text: 'Caractere especial',
    rule: hasSpecialCharacters,
  },
];

export const usePasswordStr = (password: string = '') => {
  const [isAllValid, setIsAllValid] = useState<any>();
  const [testedValidators, setTestedValidators] = useState<any>([]);

  useEffect(() => {
    (function () {
      const _ = passwordValidators.map((validator) => ({
        ...validator,
        isValid: validator.rule(password),
      }));

      setIsAllValid(_.every(({ isValid }: any) => isValid === true));
      setTestedValidators(_);
    }());
  }, [password]);

  return { testedValidators, setTestedValidators, isAllValid };
};
