const isStaging = window.location.host.includes('localhost')
|| window.location.host.includes('staging')
|| window.location.host.includes('stg');

// const getApiUrl = () => {
//   if (isStaging) return 'https://staging-engineering.alliar.com/middle-earth';

//   return 'https://tech.alliar.com/middle-earth';
// };

// Por conta do problema do pleres em staging, teremos que utilizar a url de prod
const baseURL = 'https://tech.alliar.com/middle-earth';

export {
  isStaging, baseURL,
};
