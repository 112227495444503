import {
  AppointmentExams, Appointments, ExamDetails,
} from '../views';

export const privateRoutes = [
  {
    path: '/',
    Component: Appointments,
  },
  {
    path: '/atendimentos/:appointmentNumber-:appointmentId',
    Component: AppointmentExams,
  },
  {
    path: '/atendimentos/detalhesexame/:examIdentifier',
    Component: ExamDetails,
  },
];
