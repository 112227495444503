import { Link } from 'react-router-dom';
import styled from 'styled-components';
import {
  compose,
  space,
  SpaceProps,
  color,
  ColorProps,
  layout,
  LayoutProps,
  flexbox,
  FlexboxProps,
  typography,
  TypographyProps,
} from 'styled-system';

import {
  Card as JCard,
  Button as JButton,
} from '@alliar/jarvis';

import bgImage from './assets/imgs/bg_image.png';
import { GridItemProps } from './types';

let breakpoints = ['18.75rem', '48rem'];

breakpoints = {
  sm: breakpoints[0],
  lg: breakpoints[1],
} as any;

export const H1 = styled.h1<TypographyProps | SpaceProps>`
  ${compose(typography, space)}
  font-family: "Poppins", "sans-serif";
  font-style: normal;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 2.25rem;
  /* identical to box height */
  color: #191d45;
  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
`;

export const H3 = styled.h3<SpaceProps>`
  ${compose(space)}
  font-family: "Poppins", "sans-serif";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
  text-align: center;
`;

export const Button = styled(JButton) <SpaceProps>`
  ${compose(space)}
`;

export const Form = styled.form<SpaceProps>`
  ${compose(space)}
  display: flex;
  flex-flow: column;
  align-items: end;
  width: 100%;
  min-width: 22.5rem;
  gap: 1.5rem;

  @media (max-width: 37.5rem) {
    min-width: 100%;
  }
`;

export const FormCardHeader = styled.div<SpaceProps>`
  ${compose(space)};
  display: flex;
  align-items: center;
  flex-flow: column;
`;

export const RadioList = styled.ul`
  list-style: none;
  width: 100% ;
  padding: 0;
`;

export const RadioText = styled.p`
padding: 0;
margin: 0;
`;

export const ThemeLink = styled(Link) <SpaceProps>`
  ${compose(space)}
  font-family: 'Poppins', sans-serif;
  color: #000;
`;

export const Container = styled.div`
  z-index: 10;
  position: relative;
  display: flex;
  flex-direction: column;
`;

export const FormContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: space-between;

  #Header {
    div {
      align-items: center;
    }
  }
`;

export const FormContainerWrapper = styled.div<FlexboxProps>`
  ${compose(flexbox)};
`;

export const Row = styled.div<FlexboxProps | LayoutProps>`
  ${compose(flexbox, layout)}
  display: flex;
  flex-direction: row;
  justify-content: "start";
  width: 100%;
`;

export const Col = styled.div<FlexboxProps | TypographyProps | LayoutProps>`
  ${compose(flexbox, typography, layout)}
  flex-direction: column;
  display: flex;
`;

export const Card = styled(JCard) <LayoutProps | ColorProps>`
  ${compose(layout, color)}
  display: flex;
  flex-flow: column;
  align-items: center;
`;

export const SubHeader = styled.div`
  width: 100%;
  background-image: ${`url(${bgImage})`};
  background-position: center;
  background-size: cover;
  overflow: hidden;
  height: 7.5rem;
`;

export const SubHeaderTitle = styled.h1`
  font-family: Poppins;
  font-size: 1.5rem;
  font-weight: 400;
  color: white;
`;

export const Grid = styled.div`
  display: grid;
  grid-template:
    [header-start] "header header header" min-content [header-end]
    [sub-header-start] "sub-header sub-header sub-header" 7.5rem [sub-header-end]
    [content-start] ". content ." auto [content-end]
    [footer-start] "footer footer footer" min-content [footer-end];
  grid-template-columns: auto 79rem auto;
  justify-items: center;

  @media(max-width: 79rem){
    grid-template-columns: auto 1fr auto;
  }
`;

export const GridItem = styled.div<GridItemProps>`
  grid-area: ${(props) => props.gridArea || 'auto'};
  width: 100%;
`;

export const AppointmentContainer = styled.div`
  display: flex;
  max-width: 79rem;
  height: 100%;
  margin: 0 auto;
  padding: 1.25rem;
  align-items: center;
  justify-content: space-between;
`;

export const AppointmentContentContainer = styled(Container)`
  margin: 2.5rem 0 ;
  flex-flow: column;
  gap: 0.875rem; 
  height: unset;
`;

export const SubTitleWrapper = styled.span`
  display: flex;
  align-items: center;
  gap: max(.57em, 0.5rem);
`;

export const ImgRecaptcha = styled.img`
  max-width: 3.75rem;
`;

export const RecaptchaCol = styled(Col) <
  LayoutProps | TypographyProps | LayoutProps
  >`
  ${compose(flexbox, typography, layout)}
  margin-block: 2em;
  max-width: 25rem;
  > h5 {
    font-family: "Poppins", "sans-serif";
    font-weight: 400;
    line-height: 1.725rem;
  }
`;

export const LabelContainer = styled.span`
  display: flex; 
  justify-content: space-between;
  flex: 1;
  text-transform: capitalize;
`;

export const RightSideInfo = styled.span`
  margin-left: .5rem;
  color: #787878; 
`;

export const Box = styled.div<SpaceProps | LayoutProps>`
  ${compose(space, layout)};
  width: 100%;
`;

export default {
  breakpoints,
};
