import styled from 'styled-components';
import {
  compose,
  flexbox, grid, layout, space, typography,
} from 'styled-system';
import { ItemContainerProps } from './BodyInfoItem.types';

export const ItemContainer = styled.span<ItemContainerProps>`
  ${compose(space, grid, flexbox, layout, typography)}
`;
