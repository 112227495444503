import { Login, PasswordRecovery } from '../views';
import { PasswordChange } from '../views/PasswordChange';
import { NewPassword } from '../views/NewPassword';

export const publicRoutes = [
  {
    path: '/login',
    Component: Login,
  },
  {
    path: '/recuperacao-senha/:recoveryToken',
    Component: NewPassword,
  },
  {
    path: '/recuperacao-senha',
    Component: PasswordRecovery,
  },
  {
    path: '/alterar-senha/:token',
    Component: PasswordChange,
  },
];
