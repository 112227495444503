import { LogoProps } from './types';
import * as S from './styles';

import { getBrand } from '../../../services/config/getBrand';
import { captalizeWord } from '../../../utils/captalizeWord';

export const Logo = ({ white = false, ...rest }: LogoProps) => {
  const logoURL = `${getBrand.assets_url}logo${white ? '-white' : ''}.png`;
  const logoAlt = `Logo da marca ${captalizeWord(getBrand.name)}`;

  return (
    <S.LogoWrapper {...rest}>
      <S.LogoImg
        src={logoURL}
        alt={logoAlt}
      />
    </S.LogoWrapper>
  );
};
