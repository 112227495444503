import { useAuth } from '../../../hooks/useAuth';
import { ExamCard } from '../../../components/organisms/Cards';
import { ExamItem } from '../../../types';
import { AppointmentExamsListProps } from './AppointmentExamsList.types';

export const AppointmentExamsList = ({ exams, modalControl }: AppointmentExamsListProps) => {
  const { user } = useAuth();

  return (exams
    ? exams.map((exam: ExamItem) => (
      <ExamCard
        key={exam?.exam_id}
        examId={exam?.exam_id}
        patientName={user?.patientName || ''}
        examName={exam?.exam_name}
        examStatus={exam?.status}
        deliveryDate={exam?.delivery_date || '--/--/----'}
        modalControl={modalControl}
      />
    ))
    /* "as any" is used because this return type is
    not compatible with the type of the component AppointmentExamsList
    We need to do "Nada para mostrar" 😅 */
    : (<p>Nada para mostrar</p>) as any);
};
