import styled from 'styled-components';

export const ModalWrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  width: 24.875rem;
 `;

export const ModalIconTop = styled.div`
  font-size: 3rem;
  line-height: 0;
  padding: 0.625rem; 
  border-radius: 50%;
  background-color: #e1f0ca;
  color: #49a41d ;
 `;

export const ModalTitle = styled.h2`
  font-weight: 500;
  margin: 0;
  padding: 1.75rem 0;
  `;

export const ModalText = styled.p`
  font-weight: 400;
  text-align: center;
  margin: 0;
  padding-bottom: 1.75rem;
`;
