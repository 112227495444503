import styled from 'styled-components';

export const Breadcrumb = styled.div`
  font-family: 'Poppins', sans-serif;
  padding: 1rem 1.5rem .5rem;
  color: #666666;
  > a {
    color: #3484c3;
    text-decoration: none;
    font-weight: 500;
  }
`;
