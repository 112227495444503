import { Input } from '@alliar/jarvis';
import { ChangeEvent, useState } from 'react';
import { FormProps } from '../types';
import * as S from '../../../../theme';

export const PasswordForm = ({
  formData,
  formErros,
  onChange,
}: FormProps) => {
  const [passwordisVisible, setPasswordisVisible] = useState(false);

  return (
    <S.Box mt={4}>
      <Input
        id="PasswordFormInput"
        rightIcon={passwordisVisible ? 'eye-off' : 'eye'}
        rightIconClick={() => setPasswordisVisible(!passwordisVisible)}
        type={passwordisVisible ? 'text' : 'password'}
        label="Digite sua senha"
        name="password"
        value={formData.password || ''}
        onChange={
          (e: ChangeEvent<HTMLInputElement>) => onChange(e)
        }
        hintText={formErros?.password ? formErros?.password[0] : undefined}
        color={(formErros?.password && formErros?.password.length) ? 'red' : ''}
        autoFocus
      />
    </S.Box>
  );
};
