import { useNavigate } from 'react-router-dom';
import { AppointmentCard } from '../../../components/organisms/Cards/AppointmentCard';

import { AppointmentItem } from '../../../types';
import { AppointmentsListProps } from './AppointmentsList.types';

export const AppointmentsList = ({ appointments }: AppointmentsListProps) => {
  const navigate = useNavigate();

  return (
    appointments
      ? (
        <>
          {appointments.map((appointment: AppointmentItem) => {
            const appointmentLink = `/atendimentos/${appointment?.appointment_number}-${appointment?.appointment_id}`;

            return (
              <AppointmentCard
                key={appointment?.appointment_number}
                appointmentNumber={appointment?.appointment_number}
                deliveryDate={appointment?.delivery_date || '--/--/----'}
                appointmentDate={appointment?.date_entry}
                onClick={
                () => navigate(appointmentLink)
              }
              />
            );
          })}
        </>
      )
      : <span>Nada para mostrar</span>
  );
};
