import axios from 'axios';
import { baseURL } from './getBaseUrl';
import { getBrand } from './getBrand';

import {
  requestInterceptor,
  responseSuccessInterceptor,
  responseErrorInterceptor,
} from './interceptors';

const httpClient = axios.create({
  baseURL,
  headers: {
    'Content-Type': 'application/json',
    brand: getBrand.subdomain,
  },
});

httpClient.interceptors.request.use(requestInterceptor);
httpClient.interceptors.response.use(
  responseSuccessInterceptor,
  responseErrorInterceptor,
);

export { httpClient };
