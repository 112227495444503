import styled from 'styled-components';

export const AvatarContainer = styled.div`
  display: flex;
  align-items: center;
  color: #3484c3;
  gap: max(.57em, 0.5rem);
  user-select: none;
  font-size: .875rem;

  #Header_userName {
    @media(max-width: 37.5rem) {
      display: none;
    }
  }
`;

export const Container = styled.div`
  display: flex;
  max-width: 79rem;
  height: 100%;
  margin: 0 auto;
  padding: 1.25rem;
  align-items: center;
`;

export const HeaderWrapper = styled(Container)`
  justify-content: space-between;
`;

export const HeaderContainer = styled.header`
  width: 100%;
  height: 6rem;
  background-color: white;

  @media(max-width: 37.5rem) {
    height: 4.75rem;
  }
`;
