import { ThemeProvider } from 'styled-components';
import { defaultTheme } from '@alliar/jarvis';
import { useEffect } from 'react';

import theme from './theme';
import Routes from './routes';

import { AuthProvider } from './contexts/authContext';
import {
  brandEssentials,
} from './services/brandEssentials';
import { removeStorageItem, setStorageItem } from './utils/localStorage';

const App = () => {
  const fetchBrand = async () => {
    const { data } = await brandEssentials(window.location.host);

    if (data) {
      removeStorageItem('Brand');
      setStorageItem('Brand', data);
    }
  };

  useEffect(() => {
    fetchBrand();
  }, []);

  return (
    <ThemeProvider theme={{ ...defaultTheme, ...theme }}>
      <AuthProvider>
        <Routes />
      </AuthProvider>
    </ThemeProvider>
  );
};
export default App;
