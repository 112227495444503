import { getStorageItem } from '../../utils/localStorage';
import { brandItems } from '../types';

const defaultBrand = {
  name: 'cdb',
  subdomain: 'cdb_homolog',
  assets_url: 'https://assets.alliar.com/rol/cdb/',
};

export const getBrand = getStorageItem<brandItems>('Brand') ?? defaultBrand;
