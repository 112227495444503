import { httpClient } from './config';
import { removeCpfMask } from '../utils/cpfMask';
import { getBrand } from './config/getBrand';
import { getCarestreamUrlProps } from './types';

export const getCarestreamUrl = async (
  {
    cpf,
    examId,
    patientName,
    callback,
  }: getCarestreamUrlProps,
) => {
  const brand = getBrand.name;
  const formattedCpf = removeCpfMask(cpf);

  await httpClient.post<any, string>(
    `/proxy/get_carestream_url?app=rol&app_version=1.0&brand=${brand}&action=view-image&cpf=${formattedCpf}&exam_id=${examId}&patient=${patientName}`,
  ).then((res) => {
    const hasValidResponse = typeof res === 'string' && !res.includes('HTML');
    const carestreamRes = hasValidResponse ? res : '';

    return callback && callback(carestreamRes);
  });
};
