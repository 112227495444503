import styled from 'styled-components';
import {
  background,
  border,
  color,
  compose,
  flexbox,
  grid,
  layout,
  space,
  typography,
} from 'styled-system';
import { CardBodyProps, CardContainerProps, CardHeaderProps } from './Card.types';

export const CardContainer = styled.div<CardContainerProps>`
  border: 0.063rem solid #E5E5E5;
  background-color: #FFFFFF;
  border-radius: 0.75rem;
  width: 100%;
  padding: 1rem;
  color: #4B4B4B;
  font-size: 0.875rem;
  gap: 1rem;
  position: relative;
  overflow: hidden;

  @media(max-width: 48rem) {
    flex-flow: column;
  }
  &:before {
    content: ' ';
    background: ${(props) => (props.cardStatus ? props.cardStatus : '#fff')};
    position: absolute;
    left: 0;
    top: 0;
    width: 0.5rem;
    height: 100%;
  }
`;

export const CardHeadLine = styled.div<CardHeaderProps>`
  ${compose(space, grid, flexbox, layout, typography)}
`;

export const CardHeadLineEm = styled.em<CardHeaderProps>`
  ${compose(space, color, typography)}
  font-style: normal;
`;

export const CardHeadLineTitleWrapper = styled.div<CardHeaderProps>`
  ${compose(flexbox, layout)}
`;

export const DesktopCardHeadLineButtons = styled.div<CardHeaderProps>`
  ${compose(layout, flexbox, space)}
  font-style: normal;
`;

export const MobileCardHeadLineButtons = styled.div<CardHeaderProps>`
  ${compose(layout, flexbox, space)}
  font-style: normal;
`;

export const CardBody = styled.div<CardBodyProps>`
  ${compose(grid, layout, flexbox, space, border, color, background)}
`;
