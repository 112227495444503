import styled from 'styled-components';
import { color, ColorProps, compose } from 'styled-system';
import { ContainerProps } from './Footer.types';

const FooterContainer = styled.div<ContainerProps | ColorProps>`
  ${compose(color)}
  width: 100%;
  background-color: white; 
  padding-inline: 1.25rem;
  padding-block: 1em;
  font-size: .875rem;
  font-family: var(--ff-body);
  text-transform: uppercase;
  text-align: center;

  a {
    color:white;
  }
`;

export { FooterContainer };
