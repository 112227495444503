import { Button, Input } from '@alliar/jarvis';
import { ChangeEvent } from 'react';
import { Form } from '../../../../theme';
import { applyCpfMaskOnTargetValue } from '../../../../utils/cpfMask';
import { FormProps } from '../types';

export const PasswordRecoveryForm = ({
  formData,
  formErros,
  isSubmitting,
  handleSubmit,
  onChange,
}: FormProps) => (
  <Form onSubmit={handleSubmit} id="PasswordRecovery" style={{ marginTop: 24 }}>
    <Input
      label="Digite seu CPF"
      id="cpf"
      type="text"
      name="cpf"
      value={formData.cpf || ''}
      onChange={
        (e: ChangeEvent<HTMLInputElement>) => onChange(applyCpfMaskOnTargetValue(e))
      }
      placeholder="CPF"
      hintText={formErros.cpf ? formErros.cpf[0] : ''}
      color={(formErros?.cpf && formErros?.cpf.length) ? 'red' : ''}
      maxLength={11}
      autoFocus
    />
    <Button
      type="submit"
      disabled={isSubmitting || !formData.cpf}
      variant="main"
      label={isSubmitting ? 'Carregando...' : 'Enviar'}
      width="100%"
      style={{
        backgroundColor: isSubmitting ? '#ccc' : '',
        border: isSubmitting ? '0.063rem solid #ccc' : '',
      }}
    />
  </Form>
);
