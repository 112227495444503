import * as S from '../theme';

export const linkSwitch = (data: number) => {
  if (data === 2) {
    return (
      <S.ThemeLink
        to="/recuperacao-senha"
        mx="auto"
        mt="2rem"
      >
        Esqueci a senha
      </S.ThemeLink>
    );
  }

  if (data === 3) {
    return (
      <S.ThemeLink
        to="/"
        mx="auto"
        mt="2rem"
      >
        Voltar para o login
      </S.ThemeLink>
    );
  }

  return null;
};
