import styled from 'styled-components';
import {
  compose, layout, LayoutProps, space, SpaceProps,
} from 'styled-system';

export const LogoImg = styled.img`
  width: 100%;
`;

export const LogoWrapper = styled.div<SpaceProps | LayoutProps>`
  ${compose(space, layout)}
  max-width: 11.125rem;
`;
