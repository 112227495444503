import { ChangeEvent } from 'react';
import { cpf } from 'cpf-cnpj-validator';

export const applyCpfMaskOnTargetValue = (e: ChangeEvent<HTMLInputElement>) => {
  const { value: cpfNum } = e.target;
  e.target.value = cpf.format(cpfNum);
  return e;
};

export const removeCpfMask = (cpfValue: string) => cpfValue.replace(/\D/g, '');
