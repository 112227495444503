import {
  BrowserRouter, Navigate, Route, Routes,
} from 'react-router-dom';

import { useAuth } from '../hooks/useAuth';
import { Guard } from './Guard.routes';
import { privateRoutes } from './private.routes';
import { publicRoutes } from './public.routes';

const RolRoutes = () => {
  const { isLoggedIn } = useAuth();

  return (
    <BrowserRouter basename="pacientes">
      <Routes>
        <Route
          path="*"
          element={<Navigate to="/" replace />}
        />
        {/* Rotas Privadas */}
        {privateRoutes.map(({ path, Component }) => (
          <Route
            key={path}
            path={path}
            element={(
              <Guard validation={isLoggedIn} urlRedirect="/login">
                <Component />
              </Guard>
            )}
          />
        ))}

        {/* Rotas Públicas */}
        {publicRoutes.map(({ path, Component }) => (
          <Route
            key={path}
            path={path}
            element={isLoggedIn ? <Navigate to="/" /> : <Component />}
          />
        ))}
      </Routes>
    </BrowserRouter>

  );
};

export default RolRoutes;
