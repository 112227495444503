import { Avatar } from '../../atoms/Avatar';
import { Logo } from '../../atoms/Logo';
import {
  HeaderContainer, HeaderWrapper, AvatarContainer,
} from './Header.styles';
import { HeaderProps } from './Header.types';

export const Header = ({
  userName,
  children,
}: HeaderProps) => {
  const getUserInitials = () => (
    // @ts-ignore
    userName.match(/(^\S\S?|\b\S)?/g).join('').match(/(^\S|\S$)?/g).join('')
      .toUpperCase()) || '';

  return (
    <HeaderContainer>
      <HeaderWrapper>
        <Logo width={136} />

        <AvatarContainer>
          <Avatar bgColor="#3484c3" size="sm" outlined={false}>
            <span style={{ fontSize: '.875rem', color: 'white' }}>{getUserInitials()}</span>
          </Avatar>
          <span id="Header_userName">{userName}</span>

          {children}
        </AvatarContainer>
      </HeaderWrapper>
    </HeaderContainer>
  );
};
