import { MdHorizontalRule, MdCheck, MdClose } from 'react-icons/md';

import { ItemWrapper } from './styles';
import { ValidationsListItemProps } from './types';

export const ValidationsListItem = ({
  text,
  hasPassed = 'indeterminate',
}: ValidationsListItemProps) => {
  const getStyle = () => ({
    indeterminate: {
      icon: <MdHorizontalRule size={20} />,
      color: '#616466',
    },
    check: {
      icon: <MdCheck size={20} />,
      color: '#49A41D',
    },
    fail: {
      icon: <MdClose size={20} />,
      color: '#EC6360',
    },
  }[hasPassed]);

  return (
    <ItemWrapper style={{ color: getStyle().color }}>
      {getStyle().icon}
      <p style={{ fontFamily: 'Poppins' }}>
        {text}
      </p>
    </ItemWrapper>
  );
};
