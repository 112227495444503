import React, { useEffect, useState } from 'react';

export type ErrorsObject = { [index: string]: Array<string> }

export const useForm = <T>(
  callback: (formData: T) => unknown,
  formValidation: (formData: T) => ErrorsObject,
) => {
  const [formData, setFormData] = useState<T>({} as T);
  const [errors, setErrors] = useState<ErrorsObject>({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;

    setFormData(() => ({
      ...formData,
      [name]: value,
    }));
  };

  const handleSubmit = (event: React.SyntheticEvent) => {
    event.preventDefault();

    setErrors(formValidation(formData));

    setIsSubmitting(true);
  };

  useEffect(() => {
    const errorsObjectIsEmpty = Object.values(errors).every((value) => {
      if (value.length === 0) return true;

      return false;
    });

    if (errorsObjectIsEmpty && isSubmitting) callback(formData);

    setIsSubmitting(false);
  }, [errors]);

  return {
    formData, errors, handleChange, handleSubmit, isSubmitting,
  };
};
