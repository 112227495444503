import { MdCheck } from 'react-icons/md';
import { Avatar } from '../../../../components/atoms/Avatar';
import {
  FormCardHeader, ThemeLink, H1, H3,
} from '../../../../theme';

export const SuccessPasswordChange = () => (
  <>
    <FormCardHeader>
      <Avatar bgColor="#49A41D">
        <MdCheck color="white" />
      </Avatar>
      <H1>Sua senha foi alterada!</H1>
      <H3>
        Você já pode acessar a sua conta com a nova senha.
      </H3>
    </FormCardHeader>
    <ThemeLink to="/login" mx="auto" mt="24px">
      Voltar para o login
    </ThemeLink>
  </>
);
