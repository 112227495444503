import styled from 'styled-components';

type CircleProps = {
  outlined?: boolean,
  bgColor?: string,
  size?: 'sm' | 'md',
}

export const Circle = styled.span<CircleProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${({ size }) => (size === 'sm' ? '1.75rem' : '2.5rem')};
  width: ${({ size }) => (size === 'sm' ? '1.75rem' : '2.5rem')};
  aspect-ratio: 1;
  border-radius: 100%;
  font-size: 1.5rem;
  background-color: ${({ bgColor }) => (bgColor)};
  ${(props) => (props.outlined && 'border: 0.063rem solid #e5e5e5')};
`;
