import { cpf as cpfUtils } from 'cpf-cnpj-validator';
import { User } from '../types';

function validateCpf(cpf: string) {
  const cpfErrors: string[] = [];

  if (!cpfUtils.isValid(cpf)) cpfErrors.push('Ops! O CPF informado é inválido. Tente novamente.');

  return cpfErrors;
}

function validateLogin({ cpf }: User) {
  return {
    cpf: validateCpf(cpf),
  };
}

export { validateLogin };
