import { useCallback, useEffect, useState } from 'react';
import { Modal } from '@alliar/jarvis';
import { FiLogOut, FiCheckCircle } from 'react-icons/fi';

import { Link, useParams } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';
import { getMedicalExams } from '../../services';

import { Button } from '../../components/atoms/Button';
import { Header } from '../../components/molecules/Header';
import { MegaFooter } from '../../components/organisms/MegaFooter';

import { AppointmentExamsList } from './AppointmentExamsList';
import { ExamsListType } from '../../types';
import {
  ModalWrapper,
  ModalIconTop,
  ModalTitle,
  ModalText,
} from './AppointmentExams.styles';
import { Breadcrumb } from '../../components/organisms/Breadcrumbs';
import {
  AppointmentContainer,
  AppointmentContentContainer,
  Grid, GridItem, SubHeader, SubHeaderTitle, SubTitleWrapper,
} from '../../theme';

export const AppointmentExams = () => {
  const [exams, setExams] = useState<ExamsListType>();
  const [isLoading, setIsLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalLink, setModalLink] = useState('');
  const { user, Logout } = useAuth();

  const { appointmentNumber, appointmentId } = useParams();

  useEffect(() => {
    setIsLoading(true);
    fetchAllExams();
  }, []);

  const fetchAllExams = useCallback(async () => {
    try {
      const { data } = await getMedicalExams({ appointmentId } as any);
      setExams(data);
    } catch (error) {
      throw new Error('Can\'t fetch exams');
    } finally {
      setIsLoading(false);
    }
  }, []);

  const modalHandler = (opened: boolean, link: string) => {
    const getLink = link.replace(/['"]+/g, '');

    window.open(getLink, '_blank');
    setIsModalOpen(opened);
  };

  return (
    <Grid>
      <GridItem gridArea="header">
        <Header userName={user?.patientName || ''}>
          <Button variation="outlined" icon={<FiLogOut />} onClick={Logout}>
            Sair
          </Button>
        </Header>
      </GridItem>

      <GridItem gridArea="sub-header">
        <SubHeader>
          <AppointmentContainer>
            <SubTitleWrapper>
              <SubHeaderTitle>
                Atendimento
                {' '}
                {appointmentNumber}
              </SubHeaderTitle>
            </SubTitleWrapper>
          </AppointmentContainer>
        </SubHeader>
      </GridItem>

      <GridItem gridArea="content">
        <Breadcrumb>
          <Link to="/">
            Atendimentos
          </Link>
          {` > Atendimento ${appointmentNumber}`}
        </Breadcrumb>

        <AppointmentContentContainer>
          {
            !exams && isLoading
              ? 'Carregando...'
              : (
                <AppointmentExamsList
                  exams={exams}
                  modalControl={(opened: boolean, link: string) => {
                    setIsModalOpen(opened);
                    setModalLink(link);
                  }}
                />
              )
          }
        </AppointmentContentContainer>
      </GridItem>

      <GridItem gridArea="footer">
        <MegaFooter />
      </GridItem>

      <Modal open={isModalOpen} style={{ top: 0, left: 0, position: 'fixed' }}>
        <ModalWrapper>
          <ModalIconTop>
            <FiCheckCircle />
          </ModalIconTop>
          <ModalTitle>Este exame está disponivel</ModalTitle>
          <ModalText>
            Este exame está disponível em uma nova página,
            clique no botão abaixo para acessar.
          </ModalText>
          <Button
            onClick={() => modalHandler(false, modalLink)}
            mb="1rem"
            width="17rem"
            justifyContent="center"
          >
            Exibir Resultado
          </Button>
          <Button onClick={() => setIsModalOpen(false)} dark fontWeight="500">Voltar a listagem</Button>
        </ModalWrapper>
      </Modal>
    </Grid>
  );
};
