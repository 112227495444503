import { Button } from '@alliar/jarvis';
import { MdQueryBuilder } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { Avatar } from '../../../../components/atoms/Avatar';
import {
  FormCardHeader, ThemeLink, H1, H3,
} from '../../../../theme';
import { FormActionsWrapper } from './InvalidToken.styles';

export const InvalidToken = () => {
  const navigate = useNavigate();

  return (
    <>
      <FormCardHeader>
        <Avatar bgColor="#3484C3">
          <MdQueryBuilder color="white" />
        </Avatar>
        <H1>O seu link expirou!</H1>
        <H3>
          Este link para alterar sua senha não é mais válido.
          Solicite uma nova redefinição de senha.
        </H3>
      </FormCardHeader>

      <FormActionsWrapper>
        <Button
          type="button"
          variant="main"
          label="Redefinir senha"
          width="100%"
          onClick={() => navigate('../recuperacao-senha')}
        />

        <ThemeLink to="/login" mx="auto" mt="24px">
          Voltar para o login
        </ThemeLink>
      </FormActionsWrapper>
    </>
  );
};
