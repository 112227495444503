import { useLocation, useNavigate } from 'react-router-dom';
import {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { Button } from '../../../atoms/Button';
import { useAuth } from '../../../../hooks/useAuth';
import { getMedicalExamResults } from '../../../../services';

import { ExamCardProps } from './ExamCard.types';
import { ExamCardBodyInfoItem } from './ExamCardBodyInfoItem';

import {
  CardBody,
  CardContainer,
  CardHeadLine,
  MobileCardHeadLineButtons,
  CardHeadLineEm,
  CardHeadLineTitleWrapper,
  DesktopCardHeadLineButtons,
} from '../Card.styles';
import { getCarestreamUrl } from '../../../../services/carestreamUrl';

export const ExamCard = ({
  examId,
  examName,
  examStatus,
  deliveryDate,
  patientName,
  modalControl,
}: ExamCardProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const { user } = useAuth();
  const navigate = useNavigate();
  const breadcrumbLink = useLocation();
  const mountedRef = useRef(true);
  const [careStreamURL, setCareStreamURL] = useState('');

  const getExams = useCallback(async () => {
    setIsLoading(true);

    const examIdentifier = `exam-${examId}`;
    sessionStorage.setItem('breadcrumbAppointmentLink', breadcrumbLink.pathname);
    if (user) sessionStorage.setItem('carestreamExamId', JSON.stringify({ examId }));

    try {
      const { data } = await getMedicalExamResults({ patientName, examId } as any);
      const { result } = data;

      if ((result.startsWith('http'))) return modalControl(true, result);

      if (!mountedRef.current) return null;

      sessionStorage.setItem(examIdentifier, JSON.stringify(result));
      return navigate(`/atendimentos/detalhesexame/${examIdentifier}`);
    } catch (err) {
      throw new Error(`Exam Result Error: ${err}`);
    } finally {
      setIsLoading(false);
    }
  }, []);

  async function setImageUrl() {
    getCarestreamUrl({
      cpf: user!.cpf,
      examId,
      patientName,
      callback: setCareStreamURL,
    });
  }

  useEffect(() => {
    mountedRef.current = true;
    setImageUrl();

    return () => {
      mountedRef.current = false;
    };
  }, []);

  const statusMapColor: any = {
    DISPONÍVEL: '#7AAF27',
    'NÃO DISPONÍVEL': '#F4C52C',
  };

  const statusMap: any = {
    DISPONÍVEL: 'Laudo disponível',
    'NÃO DISPONÍVEL': 'Laudo Indisponível',
  };

  return (
    <CardContainer cardStatus={statusMapColor[examStatus]}>
      <CardHeadLine
        fontFamily="'Poppins', sans-serif"
        mx={0}
        mt={0}
        mb={{ sm: '0.875rem', lg: '0.75rem' }}
        gridGap="0.25rem"
        display="flex"
        alignItems="baseline"
        justifyContent="space-between"
        flexWrap="wrap"
        fontSize={{ sm: '0.75rem', lg: '1rem' }}
      >
        <CardHeadLineTitleWrapper
          display="flex"
          flexDirection={{ sm: 'column', lg: 'row' }}
        >
          Exame
          {' '}
          <CardHeadLineEm
            color="#0C274D"
            fontWeight="600"
            ml={{ sm: '0', lg: '0.375rem' }}
          >
            {examName}
          </CardHeadLineEm>
        </CardHeadLineTitleWrapper>

        <DesktopCardHeadLineButtons
          display={{ sm: 'none', lg: 'flex' }}
          flexDirection="row"
        >
          <Button
            mr={2}
            onClick={() => window.open(careStreamURL, '_blank')}
            disabled={!careStreamURL}
          >
            Ver imagem
          </Button>

          <Button
            onClick={() => getExams()}
            disabled={examStatus !== 'DISPONÍVEL' || isLoading}
          >
            {isLoading ? 'Carregando...' : 'Ver laudo'}
          </Button>
        </DesktopCardHeadLineButtons>
      </CardHeadLine>

      <CardBody
        width="100%"
        py={{ sm: '0.75rem', lg: '0.5rem' }}
        px="0.75rem"
        display="flex"
        justifyContent="space-between"
        flexDirection={{ sm: 'column', lg: 'row' }}
        bg="#F5F5F5"
        backgroundPosition="0% 0%"
        backgroundRepeat="no-repeat"
        borderRadius="0.25rem"
        gridGap="0.875rem"
      >
        <ExamCardBodyInfoItem title="Status" value={statusMap[examStatus]} />
        <ExamCardBodyInfoItem title="Previsão da entrega" value={deliveryDate} />
      </CardBody>

      {/* Mobile view */}
      <MobileCardHeadLineButtons
        display={{ sm: 'flex', lg: 'none' }}
        flexDirection="row"
        mt="0.75rem"
        justifyContent="center"
      >
        <Button
          mr={2}
          onClick={() => window.open(careStreamURL, '_blank')}
          disabled={!careStreamURL}
        >
          {!careStreamURL ? 'Imagem não disponível...' : 'Ver imagem'}
        </Button>
        <Button
          onClick={() => getExams()}
          disabled={examStatus !== 'DISPONÍVEL'}
        >
          {isLoading ? 'Carregando...' : 'Ver laudo'}
        </Button>
      </MobileCardHeadLineButtons>
    </CardContainer>
  );
};
