import styled, { css } from 'styled-components';
import {
  compose,
  border,
  color,
  layout,
  space,
  typography,
  flexbox,
} from 'styled-system';
import { ButtonProps } from './Button.types';

export const ButtonWrapper = styled.button<ButtonProps>`
  ${compose(layout, space, typography, color, border, flexbox)}

  ${(props) => !props.disabled
    && css`
      &:hover,
      &:focus {
        cursor: pointer;
        opacity: 0.7;
      }
    `}
`;

export const ButtonIconWrapper = styled.span<ButtonProps>`
  ${compose(space)}
`;
