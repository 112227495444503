import { FiLogOut } from 'react-icons/fi';

import { useParams, Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { ReactComponent as DownloadIcon } from '../../assets/icons/icon-download.svg';
import { ReactComponent as ImageIcon } from '../../assets/icons/icon-image.svg';

import { useAuth } from '../../hooks/useAuth';

import { Button } from '../../components/atoms/Button';
import { Header } from '../../components/molecules/Header';
import { MegaFooter } from '../../components/organisms/MegaFooter';

import {
  ActionsHeader,
  Subtitle,
  ContentWrapper,
} from './ExamDetails.styles';
import { Breadcrumb } from '../../components/organisms/Breadcrumbs';
import { getCarestreamUrl } from '../../services/carestreamUrl';
import {
  AppointmentContainer,
  Grid, GridItem, SubHeader, SubHeaderTitle, SubTitleWrapper,
} from '../../theme';

function downloadURL(bytesBase64: string, fileName: string, mimeType = 'application/pdf') {
  const fileUrl = `data:${mimeType};base64,${bytesBase64}`;
  fetch(fileUrl)
    .then((response) => response.blob())
    .then((blob) => {
      const link = window.document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
}

export const ExamDetails = () => {
  const [examBase64, setExamBase64] = useState('');
  const [breadcrumbLink, setBreadcrumbLink] = useState('/');
  const [careStreamURL, setCareStreamURL] = useState('');

  const { user, Logout } = useAuth();
  const { examIdentifier } = useParams();
  const isMobile = window.innerWidth <= 768;

  const checkSessionExamBase64 = sessionStorage.getItem(`${examIdentifier}`);

  async function setImageUrl() {
    const res = JSON.parse(sessionStorage.getItem('carestreamExamId') || '');

    getCarestreamUrl({
      cpf: user!.cpf,
      examId: res.examId,
      patientName: user!.patientName,
      callback: setCareStreamURL,
    });
  }

  useEffect(() => {
    setBreadcrumbLink(sessionStorage.getItem('breadcrumbAppointmentLink') || '/');
    setExamBase64(checkSessionExamBase64?.replace(/"/g, '') || '');

    setImageUrl();
  }, []);

  const reportLegend = `Ver laudo - ${examIdentifier?.replace('exam-', '')}`;

  return (
    <Grid>
      <GridItem gridArea="header">
        <Header userName={user?.patientName || ''}>
          <Button variation="outlined" icon={<FiLogOut />} onClick={Logout}>
            Sair
          </Button>
        </Header>
      </GridItem>

      <GridItem gridArea="sub-header">
        <SubHeader>
          <AppointmentContainer>
            <SubTitleWrapper>
              <SubHeaderTitle>
                {reportLegend}
              </SubHeaderTitle>
            </SubTitleWrapper>
          </AppointmentContainer>
        </SubHeader>
      </GridItem>

      <GridItem gridArea="content">
        <Breadcrumb>
          <Link to="/">
            Atendimentos
          </Link>
          {' > '}
          <Link to={breadcrumbLink}>
            Atendimento
            {' '}
            {breadcrumbLink.replace(/(^[/atendimentos/]+)|(-\d{7})$/g, '')}
          </Link>
          {` > ${reportLegend}`}
        </Breadcrumb>
        <ContentWrapper>
          <ActionsHeader isMobile={isMobile}>
            {
              isMobile
              && <Subtitle>Escolha a melhor opção para você:</Subtitle>
            }

            <Button icon={<DownloadIcon width={15} />} onClick={() => downloadURL(examBase64, `${examIdentifier}`)}>
              Baixar PDF
            </Button>

            <Button
              icon={<ImageIcon width={15} />}
              onClick={() => window.open(careStreamURL, '_blank')}
              disabled={!careStreamURL}
            >
              Ver imagem
            </Button>
          </ActionsHeader>

          {
            !isMobile
            && (
              <div style={{
                position: 'relative', width: '100%', height: '0', paddingBottom: '56.25%',
              }}
              >
                <embed
                  src={`data:application/pdf;base64,${examBase64}`}
                  type="text/html"
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                  }}
                />
              </div>
            )
          }

        </ContentWrapper>
      </GridItem>

      <GridItem gridArea="footer">
        <MegaFooter />
      </GridItem>
    </Grid>
  );
};
