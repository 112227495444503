import {
  RadioGroup, RadioInput, RadioLabel, RadioWrapper,
} from '@alliar/jarvis';

import * as S from '../../../../../theme';
import { RadioButtonProps, RadioProps } from '../types';

export const RadioButton = ({
  items,
  chekedValue,
  setChekedValue,
}: RadioButtonProps) => (
  <S.Box
    pt=".5rem"
    pb="2rem"
    px={{ lg: '2rem', sm: '0' }}
  >
    <RadioGroup
      checked={chekedValue}
      name="multi-account"
      onChange={setChekedValue}
    >
      {items.map(({ prontuarioId, patientName, patientBirth }: RadioProps) => (
        <RadioWrapper
          id={prontuarioId.toString()}
          key={prontuarioId}
          value={prontuarioId.toString()}
        >
          <RadioLabel>
            <RadioInput />
            <S.LabelContainer>
              {patientName.split(' ')[0].toLowerCase()}

              <S.RightSideInfo>
                {`**/**/${patientBirth.split('-')[0]}`}
              </S.RightSideInfo>
            </S.LabelContainer>
          </RadioLabel>
        </RadioWrapper>
      ))}
    </RadioGroup>
  </S.Box>
);
