import styled, { css } from 'styled-components';

export const ActionsHeader = styled.div<{ isMobile: boolean }>`
  width: 100%;
  display: flex;
  justify-content: end;
  gap: 1.5rem;
  margin-block: 1rem;

  ${(props) => (
    props.isMobile
    && css`
      flex-flow: column;  
      justify-content: center;
    `
  )}
`;

export const Subtitle = styled.p`
  color: #4B4B4B;
  text-align: center;
  font-size: .75rem;
  margin-bottom: 0;
`;

export const ContentWrapper = styled.div`  
  padding-inline: 1.25rem;
  margin-bottom: 7.063rem
`;
