import { Circle } from './Avatar.styles';
import { AvatarProps } from './Avatar.types';

export const Avatar = ({ children, ...rest }: AvatarProps) => (
  <Circle {...rest}>
    {children}
  </Circle>
);

Avatar.defaultProps = {
  outlined: true,
  bgColor: 'transparent',
  size: 'md',
};
