import { ListWrapper } from './styles';

import { ValidationsListItem } from './ValidationsListItem';

export const ValidationsList = ({
  testedValidators,
  dataToVerify,
}: any) => (
  <ListWrapper>
    {testedValidators.map((validator: any) => (
      <ValidationsListItem
        key={validator.text}
        text={validator.text}
        hasPassed={dataToVerify ? (validator.isValid ? 'check' : 'fail') : 'indeterminate'}
      />
    ))}
  </ListWrapper>
);
