import { Navigate, useLocation } from 'react-router-dom';
import { GuardRouteProps } from './types.routes';

export const Guard = ({
  children,
  validation,
  urlRedirect,
}: GuardRouteProps & { urlRedirect: string }) => {
  const location = useLocation();

  if (validation) return children;

  return (<Navigate to={urlRedirect} state={{ from: location }} replace />);
};
